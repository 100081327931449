<template>
  <el-container>
    <el-header style="min-width:1360px" class="flex flex-v-center bg-white fixed flex-h-center header ">

      <el-image @click="toIndex()" class="logo menu pointer" src="/web/image/logo.png" fit="cover"></el-image>
      <el-menu ref="topNav" text-color="#303030" active-text-color="#E6A23C" router :default-active="active"
        mode="horizontal">
        <el-menu-item @click="toMain('PTE')" index="" class="menu bold  m-hover"><span>{{ $t('首页')
            }}</span></el-menu-item>
        <el-submenu index="/main/pte" class="menu0 bold  m-hover">
          <template slot="title">
            <span @click="toMain('PTE')">{{ $t('PTE练习') }}</span>
          </template>
          <div class="flex padding flex-h-center" style="width:100vw;">
            <div class="margin-x" v-for="(sort, index) in $store.getters.getSorts(0)" :key="index">
              <h3 style="margin-left:6px;">{{ sort.title }}</h3>
              <div class="mt1 margin-ts pointer ul-hover" v-for="(type, index1) in sort.types" :key="index1"
                @click="to('/main/pte?tag=' + type.tag, 'PTE')">{{ type.title }} <span class="st" v-if="type.ai_flag">AI
                  {{ $t('评分') }}</span></div>
            </div>
            <div class="margin-x sort4">
              <h4 style="margin-left:6px;">More</h4>
              <div @click="walkman()" class="mt1 margin-ts pointer ul-hover">{{ $t('PTE随身听') }}</div>
              <div @click="to('/main/download', 'PTE')" class="mt1 margin-ts pointer ul-hover">{{ $t('题库一键导出 PDF') }}
              </div>
              <div @click="to('/main/wordbook', 'PTE')" class="mt1 margin-ts pointer ul-hover">{{ $t('PTE词库') }} </div>
              <!-- <div @click="to('/main/favorites','PTE')" class="mt1 margin-ts pointer ul-hover">{{$t('我的收藏')}} </div> -->
              <div @click="to('/main/mocks', 'PTE')" class="mt1 margin-ts pointer ul-hover">{{ $t('PTE模考') }} </div>

            </div>
          </div>
        </el-submenu>


        <el-submenu :class="{'active':active == '/media'}"  index="/course" class="menu0 bold">

          <template slot="title"><span>{{ $t('PTE课程') }}</span></template>

          <el-menu-item :key="index" v-for="(course, index) in $store.getters.getCourses('PTE')"
            :index="'/media?tag=course&id=' + course.id" class="menu bold m-hover"
            style="font-size: 14px !important;">{{
              course.title }}</el-menu-item>

        </el-submenu>

        <el-menu-item index="/article" class="menu bold  m-hover"><span>{{ $t('备考入门') }}</span></el-menu-item>

        <el-menu-item @click="toMain('CCL')" index="/ccl" class="menu bold  m-hover"><span>CCL</span></el-menu-item>

      </el-menu>


      <search-input></search-input>

      <el-image v-if="$store.state.user" style="width:50px; height: 50px;" fit="scale-down" class="radius-c"
        :src="$host + $store.state.user.avatar || '/web/image/logo0.png'">
      </el-image>

      <el-menu text-color="#303030" v-if="$store.state.user" active-text-color="#E6A23C" mode="horizontal" router>
        <el-menu-item class="menu1" index="exit1"><span @click="exit" class="tag">{{ $t('退出登录') }}</span></el-menu-item>
      </el-menu>

      <el-menu text-color="#303030" v-else active-text-color="#E6A23C" router :default-active="active"
        mode="horizontal">
        <el-menu-item index="/login" class="menu1 bold m-hover"><span>{{ $t('登录') }}</span></el-menu-item>
        <el-menu-item class="menu2 no-cursor" style="border-bottom:none;color: #303133 !important;">|</el-menu-item>
        <el-menu-item index="/regist" class="menu2  bold m-hover"><span>{{ $t('注册') }}</span></el-menu-item>
      </el-menu>



      <el-menu v-if="!$store.state.isTw" text-color="#303030" active-text-color="#E6A23C" mode="horizontal">
        <el-menu-item v-if="$store.state.lang == 'tw'" @click="setLang('')" class="menu1"><span @click="exit"
            class="tag">简体</span></el-menu-item>
        <el-menu-item v-else class="menu1" @click="setLang('tw')"><span @click="exit" class="tag">{{ $t('繁体', 'tw')
            }}</span></el-menu-item>
      </el-menu>

      <!-- <div v-if="!$store.state.isTw">
        <span class="tag pointer" @click="setLang('')" v-if="$store.state.lang == 'tw'"
          style="margin-left: 30px;">简体</span>
        <span class="tag pointer" @click="setLang('tw')" v-else style="margin-left: 30px;">{{ $t('繁体', 'tw') }}</span>
      </div> -->
    </el-header>

    <el-main class="main">
      <router-view></router-view>
    </el-main>

    <c-footer v-if="footer"></c-footer>

  </el-container>
</template>

<script>
import cFooter from '../../components/CFooter.vue';
import searchInput from '../../components/SearchInput.vue';

export default {
  components: {
    searchInput, cFooter
  },
  data: function () {
    return {
      active: "/",
      footer: true,
      search: "",
      menuOpened: false,

    }
  },
  watch: {
    $route(route) {
      this.active = route.path;
      if (route.path == "/login" || route.path == "/regist") {
        this.footer = false;
      } else {
        this.footer = true;
      }
    }
  },

  created() {

    this.$bus.$on("toggle_menu", () => {

      if (this.$refs.topNav) {
        this.$refs.topNav.close("/main/pte");
        this.$refs.topNav.close("/main/ccl");
      }

    })



    this.active = this.$route.path
    if (this.active == "/login" || this.active == "/regist") {
      this.footer = false;
    }
  },
  methods: {

    setLang(lang) {
      this.$store.commit("setLang", lang)
    },

    toMain(mode) {
      if (this.$store.state.user == null && mode == "PTE") {
        this.$router.replace("/");
      } else {
        this.$store.commit("setMode", mode);
        this.$refs.topNav.close("/main");
        this.$router.replace("/main");
      }
    },

    toIndex() {
      this.$router.replace("/")
    },

    walkman() {
      window.open("https://www.ptefighter.com/walkman")
    },
    to(url, mode) {
      this.$store.commit("setMode", mode);
      this.$router.push(url);
    },

    exit() {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      this.$store.commit("setUser", null)
      window.location.href = "/";
    },
  },
};
</script>

<style scoped>
/* .header,
.main {
min-width: 1200px;
} */


.active>>>.el-submenu__title{
    color: #E6A23C !important;
    text-decoration: underline;
    text-underline-offset: 20px;
    text-decoration-thickness: 2px;
}


.logo {
  width: 100px;
}

.tag {
  color: #f66;
  line-height: 20px;
  padding: 5px 15px;
  display: inline-block;
  border-radius: 15px;
  border: 1px solid #f66;
}

.popnav {
  display: none;
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
}


.main {
  margin-top: 80px;
}

.el-link.el-link--default:after {
  border-color: #ffffff;
}

.el-divider {
  background-color: #606266;
}


.menu {
  padding: 0;
  margin: 10px 20px;
}

.menu0 {
  padding: 0;
  margin: 10px 0;
}

.menu1 {
  padding: 0;
  margin-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.menu2 {
  margin-top: 10px;
  margin-left: 15px;
  padding: 0;
}

.header>>>.el-input__inner {
  border-radius: 20px;
  background-color: #f0f0f0;
  border: none;
}

.el-menu--horizontal {
  left: 0 !important;
}
</style>
